.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar-thumb {
  position: relative;
  top: 0px;
  float: right;
  width: 6px;
  height: 78px;
  background-color: rgb(66, 66, 66);
  border: 1px solid rgb(255, 255, 255);
  background-clip: padding-box;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  border: 1px solid #ffffff00;
  border-radius: 10px;
  padding: 2px 0;
  background-color: #dfdfdf00;
}

::-webkit-scrollbar {
  width: 10px;
}

@font-face {
  font-family: 'GucciSans-Bold';
  src: url('./resources/fonts/GucciSans-Bold.ttf');
  /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GucciSans-Book';
  src: url('./resources/fonts/GucciSans-Book.woff2');
  /* IE9 Compat Modes */
}

.w-100 {
  width: 100%;
}

.grid {
  display: grid;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0)
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.spinner_cont {
  position: absolute;
  width: 100%;
  top: 36%
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #333;

  left: 50%;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

/* HEADER */
.header {
  background: black;
  position: fixed;
  left: 20px;
  top: 20px;
  z-index: 1008;
  width: calc(100% - 40px);
  height: 60px;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 62%);
}

#header-left {
  border-width: 0px;
  position: absolute;
  left: 10px;
  top: 10px;
  width: 187px;
  height: 40px;
  display: flex;
  font-family: "Arial", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  letter-spacing: normal;
  color: #333333;
  vertical-align: none;
  text-align: center;
  line-height: normal;
  text-transform: none;
}

#header-center {
  border-width: 0px;
  position: absolute;
  left: calc(50% - 76px);
  top: 18px;
  width: 152px;
  height: 25px;
  display: flex;
  text-align: center;
  line-height: normal;
  text-transform: none;
}

#header-right {
  position: absolute;
  height: 60px;
  align-items: center;
  right: 15px;
  display: flex;
}

.language {
  font-size: 16px;
  color: white;
  font-family: 'GucciSans-Bold';
  padding-right: 25px;
}

.dropdown_menu {
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 8px;
}

.setting_menu {
  padding-top: 5px;
  padding-left: 10px;
  cursor: pointer;
}

.homepage {
  width: 100%;
  height: fit-content;
}

.submenu {
  position: absolute;
  right: -15px;
  background: white;
  top: 56px;
  width: 100px;
  padding: 10px;
  font-family: 'GucciSans-Book';
  display: flex;
  box-shadow: 1px 1px 1px #80808070;
}

.submenu_hide {
  display: none;
}

.submenu_icon {
  align-self: center;
  padding-top: 4px;
  padding-right: 15px;
}

.submenu_text {
  align-self: center;
  user-select: none;
  cursor: pointer;
}

/* FIRST SECTION */

.first_section {
  width: 100%;
  background-size: contain;
  /*background-size: cover;*/
  background-repeat: no-repeat;
  /*margin-top: -77px;*/
  height: 104vh;
  position: relative;
}

.first_section_0 {
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/f0/9a/5d/f09a5dd09adc4d109d3bc49388e6cb02/images/gucci_ideas/u182_state0.png?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
}

.first_section_1 {
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/ba/de/f6/badef693c04447288768051502d5a2c6/images/gucci_ideas/u204_state0.png?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
}

.first_section_2 {
  background-image: url('resources/img/first_section.png');
}

.mobile_section {
  display: none;
}

.web_section {
  display: block;
}

/* ABOUT GUCCI SECTION */
#about_gucci {
  padding-bottom: 30px;
}

.white_shadow {
  color: white;
  font-size: 98px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 40px;
  margin-left: 25px;
  font-family: 'GucciSans-Bold';
  text-shadow: 1px 1px 0px rgb(0 0 0 / 65%);
}

.white_right_shadow {
  color: white;
  font-size: 90px;
  text-transform: uppercase;
  text-align: right;
  margin-top: 40px;
  margin-right: 25px;
  font-family: 'GucciSans-Bold';
  text-shadow: -1px 1px 0px rgb(0 0 0 / 65%);
}


.black_text {
  font-family: "GucciSans-Bold";
  font-weight: 700;
  font-style: normal;
  font-size: 80px;
  color: #000000;
  text-align: left;
  text-transform: uppercase;
  margin-top: -64px;
  margin-left: 36px;
}

.black_sub_text {
  font-family: "GucciSans-Book";
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
  color: #000000;
  text-align: left;
  text-transform: uppercase;
  margin-top: 30px;
  margin-left: 32px;
}

.black_sub_text_right {
  font-family: "GucciSans-Book";
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  color: #000000;
  text-align: right;
  text-transform: uppercase;
  margin-top: 10px;
  margin-right: 180px;
}

.black_sub_text_r {
  font-family: "GucciSans-Bold";
  font-weight: 400;
  font-style: normal;
  font-size: 80px;
  color: #000000;
  text-align: right;
  text-transform: uppercase;
  margin-top: -68px;
  margin-right: 63px;
  margin-bottom: 25px;
}


.info_text_left {
  width: 30%;
  font-family: 'GucciSans-Bold';
  font-size: 25px;
  text-transform: uppercase;
  color: #000000;
  text-align: left;
  margin-left: 173px;
  margin-top: 95px;
}

.info_text {
  width: 82%;
  margin-left: 153px;
  margin-top: 24px;
  font-family: 'GucciSans-Book';
  font-size: 20px;
  text-align: -webkit-left;
}

.hr {
  display: flex;
  /* margin-bottom: 20px;*/
}

.hr_down {
  display: flex;

}

.hr_black {
  display: flex;
}

.hr_cont {
  width: 50%;
}

.hr_cont_right {
  width: 50%;
  text-align: -webkit-right;
}

.hr_cont_right_down {
  width: 50%;
  text-align: -webkit-right;
  padding-top: 75px;

}

.hr_left {
  width: 150px;
  background-image: url('resources/img/black_bar.svg');
  background-size: cover;
  height: 7px;
  margin-left: 173px;
  margin-top: 24px;
}

.hr_right {
  width: 150px;
  background-image: url('resources/img/black_bar.svg');
  background-size: cover;
  height: 7px;
  margin-right: 173px;
  margin-top: 24px;
}

.hr_right_down {
  background-image: url('resources/img/point_class.jpg');
  height: 397px;
  background-size: cover;
  -webkit-transform: scaleY(-1);
  z-index: -2;
  position: relative;
  /* margin-top: -23px;*/
  /* margin-top: -160px;*/
}

.points_class {
  background-image: url('resources/img/point_class.jpg');
  height: 410px;
  background-size: cover;
}

.info_text_right {
  width: 80%;
  margin-right: 173px;
  margin-top: 24px;
  font-family: 'GucciSans-Book';
  font-size: 20px;
  margin-bottom: 15px;
}

.link_button {
  width: inherit;
  justify-self: right;
  padding: 10px 15px;
  border-radius: 2px;
  margin: 15px 0px;
  font-size: 15px;
  text-align: -webkit-center;
}

.link_button_white {
  background-color: white;
  color: black;
  box-shadow: 0px 0px 4px #ffffff;
}

.link_button_black {
  background-color: rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  box-shadow: 0px 0px 4px #000000;
}

.link_button_le {
  width: 350px;
}

/* BLACK SECTION */
.black_section {
  background-color: #000000;

}



.white {
  color: white;
}

.white_sub_text {
  font-family: "GucciSans-Book";
  font-weight: 400;
  font-style: normal;
  font-size: 50px;
  color: #ffffff;
  text-align: left;
  text-transform: uppercase;
  padding-top: 60px;
  margin-left: 32px;
}

.black_space {
  /* height: 390px;*/
}

.hr_left_white {
  width: 150px;
  background-image: url('resources/img/black_bar.svg');
  filter: invert(100%);
  background-size: cover;
  height: 7px;
  margin-left: 173px;
  margin-top: 24px;
}

.hr_right_white {
  width: 150px;
  background-image: url('resources/img/black_bar.svg');
  filter: invert(100%);
  background-size: cover;
  height: 7px;
  margin-right: 173px;
  margin-top: 24px;
}

.info_text_left_white {
  width: 30%;
  font-family: 'GucciSans-Bold';
  font-size: 25px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: left;
  margin-left: 173px;
  margin-top: 115px;
  line-height: 26px;
}

.info_text_right_white {
  width: 75%;
  margin-right: 173px;
  margin-top: 24px;
  font-family: 'GucciSans-Book';
  font-size: 20px;
  /* padding-bottom: 50px;*/
  color: white;
}

/* IDEA'S JOURNEY */
.up_cards_container {
  display: flex;
  width: 100%;
  margin-top: 40px;
}

.up_card_container {
  margin-left: 2.5%;
  margin-right: 12.5%;
}

.up_card {
  border-width: 0px;
  margin-left: 3.5%;
  width: 280px;
  height: 150px;
  background: inherit;
  background-color: rgba(0, 0, 0, 1);
  border: none;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #FFFFFF;
  position: relative;
  margin-right: 11%;
}

.up_card_spike {
  position: relative;
  top: -1px;
  transform: rotate(180deg);
}

.ideas_card_text {
  position: absolute;
  top: -10px;
  left: 20px;
  font-family: 'GucciSans-Bold';
  text-transform: uppercase;
  font-size: 30px;
  text-align: left;
  height: 150px;
}

.time_points_container {
  margin-top: -25px;
}

.time_point {
  margin-left: 7%;
  margin-right: 7%;
}

.bottom_cards_container {
  display: flex;
  width: 100%;
  margin-top: 20px;

  justify-content: right;
}

.bottom_card_container {
  margin-left: 12.5%;
  margin-right: 2.5%;
}

.bottom_card_spike {
  position: relative;
  top: 4px;
}

/* CAMPAIGNS' COLLECTION */
.points_class_right_up {
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/ba/de/f6/badef693c04447288768051502d5a2c6/images/gucci_ideas/pattern_u11.jpg?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
  height: 390px;
  background-size: cover;
  -webkit-transform: scaleX(-1);
  z-index: -1;
  position: relative
}

.points_class_right_down {
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/ba/de/f6/badef693c04447288768051502d5a2c6/images/gucci_ideas/pattern_u11.jpg?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
  height: 397px;
  background-size: cover;
  transform: rotate(180deg);
  z-index: -2;
  position: relative;
}

.campaign_container {
  display: flex;
  margin-top: -100px;
}

.campaign_container_left {
  width: 50%;
  place-self: center;
}

.campaign_container_right {
  width: 50%;
}

.campaign_cards_container {
  padding-bottom: 200px;
  margin-top: -150px;
  display: flex;
  z-index: 5;
  position: relative
}

.campaign_card {
  width: 435px;
  height: 435px;
  border: 11px solid;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: 0px 0px 9px grey;
  background: white;
  min-width: 365px;
  text-align: -webkit-center;
}

.campaign_card_title {
  text-transform: uppercase;
  font-family: 'GucciSans-Bold';
  font-size: 33px;
}

.campaign_card_text {
  font-family: 'GucciSans-Book';
  font-size: 21px;
  margin-top: 30px;
  width: 80%;
}

.campaign_icon {
  padding: 60px;
  width: 100px;
  border-radius: 50%;
  height: 100px;
}

.valeria_icon {
  content: url('./resources/img/valeria.jpg');

}

.hadi_icon {
  content: url('./resources/img/Hadi.jpg');
}

.elisa_icon {
  content: url('./resources/img/elisa.jpg');
}

.left_campaign_arrow {
  position: relative;
  left: 0;
  margin-top: -490px;
  background: black;
  height: 60px;
  width: 65px;
  cursor: pointer;
  z-index: 9;
  float: left;
}

.right_campaign_arrow {
  position: relative;
  right: 0;
  margin-top: -490px;
  background: black;
  height: 60px;
  width: 65px;
  cursor: pointer;
  z-index: 9;
  float: right;
}

.pt-10 {
  padding-top: 10px;
}

/*THE TEAM*/
.indiana-scroll-container--native-scroll {
  position: relative;

}

.the_team_cards_container {
  padding-bottom: 200px;
  margin-top: 90px;
  display: flex;
  z-index: 5;
  position: relative;
  place-content: center;
  min-width: 1220px;
}

/* THE LEARNING EXPERIENCE */
#the_learning_experience {
  border-top: 10PX solid;
  padding-bottom: 60px;
  /*margin-top: -50px;*/
  /*  margin-top: -20px;*/
}

.testimonials_card {
  margin-top: 60px;
  border: 10px solid;
  width: 97%;
  margin-left: 1%;
  height: 420px;
  display: flex;
  background-color: white;
  position: relative
}

.testimonials_card_icon_cont {
  width: 20%;
}

.testimonials_card_icon_white {
  margin-top: 60px;
}

.testimonials_card_icon_black {
  margin-top: -45px;
  margin-left: 225px;
}

.testimonials_card_content {
  width: 80%;
  padding: 60px;
}

.testimonials_card_title {
  text-transform: uppercase;
  font-family: 'GucciSans-Bold';
  font-size: 30px;
  text-align: left;
}

.testimonials_card_hr {
  width: 200px;
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/ba/de/f6/badef693c04447288768051502d5a2c6/images/gucci_ideas/line_u15.svg?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
  background-size: cover;
  height: 7px;
  margin-top: 24px;
  text-align: left;
}

.testimonials_card_text {
  font-family: 'GucciSans-Book';
  font-size: 24px;
  text-align: left;
  margin-top: 15px;
}

.testimonials_points_class {
  background-image: url('https://d1icd6shlvmxi6.cloudfront.net/gsc/JODI4S/ba/de/f6/badef693c04447288768051502d5a2c6/images/gucci_ideas/pattern_u11.jpg?pageId=efe8e5b7-ebc0-497b-bbf6-824cf550c8a2');
  height: 390px;
  background-size: cover;
  margin-top: -150px;
  z-index: -1;
  position: relative;
  width: 60%;
}

.left_testimonial_arrow {
  position: absolute;
  left: -29px;
  top: 43%;
  background: black;
  height: 60px;
  width: 65px;
  cursor: pointer;
}

.right_testimonial_arrow {
  position: absolute;
  right: -27px;
  top: 43%;
  background: black;
  height: 60px;
  width: 65px;
  cursor: pointer;
}

#the_platform {
  padding-bottom: 30px;
}



/* NAVBAR */

#navbar {
  position: fixed;
  height: 59px;
  width: 52%;
  left: 24%;
  bottom: 45px;
  background: black;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 62%);
  display: flex;
  z-index: 5;
}

.navbar_menu {
  width: 33.3%;
  color: white;
  font-size: 15px;
  text-transform: uppercase;
  font-family: 'GucciSans-Bold';
  padding-top: 13px;
  padding-left: 13px;
  cursor: pointer;
}

.navbar_menu_selected {
  border-bottom: 4px solid white;
}

.not_auth {
  position: absolute;
  width: 100%;
  height: 100vh;
  padding-top: 48vh;
  font-size: 25px;
  font-family: 'GucciSans-Bold';
  background: black;
  color: white;
}

.coming_soon_text {
  font-size: 40px;
  text-transform: uppercase;
}

a:link {
  text-decoration: none;
}


a:visited {
  text-decoration: none;
}


a:hover {
  text-decoration: none;
}


a:active {
  text-decoration: none;
}

@media(min-width:1600px) {
  .first_section {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: -5px;
    height: 104vh;
    position: relative;
  }


}

@media(max-width:1600px) {



  .hr_right {
    margin-right: 150px;
  }

  .hr_right_white {
    margin-right: 150px;
  }
}

@media(max-width:1500px) {
  .white_right_shadow {
    font-size: 75px;
  }

  .black_sub_text_r {
    font-size: 65px;
    margin-top: -58px;
  }

  .info_text_left {
    width: 40%;
    margin-left: 100px;
  }

  .info_text_right {
    font-size: 17px;
    margin-right: 100px;
  }

  .hr_left {
    margin-left: 100px;
  }

  .hr_right {
    margin-right: 100px;
  }

  .hr_right_white {
    margin-right: 100px;
  }

  .black_sub_text_right {
    margin-right: 100px;
  }

  .info_text_left_white {
    margin-left: 100px;
    width: 40%;
  }

  .hr_left_white {
    margin-left: 100px;
  }

  .hr_right_white {
    margin-right: 100px;
  }

  .info_text_right_white {
    margin-right: 100px;
  }

  .info_text {
    margin-left: 100px;
    width: 92%;
  }
}



@media(max-width:1300px) {



  .black_sub_text_right {
    font-size: 27px;
  }

  .info_text_right {
    margin-bottom: 5px;
  }

  .info_text_right_white {
    width: 80%;
  }

  .white_right_shadow {
    font-size: 65px;
  }

  .black_sub_text_r {
    font-size: 55px;
    margin-top: -58px;
  }

  .white_shadow {
    font-size: 80px;
  }

  .black_text {
    font-size: 70px;
  }
}

@media(max-width:1200px) {


  /* navbar */
  #navbar {
    position: fixed;
    height: 115px;
    width: 30px;
    right: 1%;
    bottom: 45px;
    background: #ffffff00;
    box-shadow: 0px 0px 0px rgb(0 0 0 / 62%);
    display: grid;
    z-index: 5;
    left: 95%;
    place-items: center;
  }

  .navbar_menu {
    width: 15px;
    color: #171717;
    font-size: 15px;
    text-transform: uppercase;
    font-family: 'GucciSans-Bold';
    padding-top: 10px;
    cursor: pointer;
    height: 15px;
    box-shadow: 1px 3px 9px #8080808a;
    border-radius: 50%;
    background-color: white;
    margin-bottom: 7px;
  }

  .navbar_menu_selected {
    border-bottom: 0;
    border: 2px solid #8e8989b8;
  }

  .navbar_menu>div {
    display: none;
  }

  #coming_soon {
    padding-bottom: 20px !important;
  }
}

@media(max-width:1100px) {
  #the_platform {
    padding-bottom: 50px;
  }

  .first_section {
    height: 70vh;
  }

  .white_shadow {
    margin-top: 50px;
    font-size: 70px;
  }

  .black_text {
    font-size: 67px;
  }

  .info_text_left {
    width: 75%;
  }


  .hr_cont {
    width: 16%;
  }

  .hr_cont_right {
    width: 100%;
  }

  .hr_cont_right_down {
    width: 100%;
    padding-top: 35px;
  }

  .points_class {
    display: none;
  }

  .hr_right_down {
    display: none;
  }

  .info_text {
    width: 83%;
    margin-right: 100px;
  }

  .info_text_right {
    width: 82%;
    margin-bottom: 20px;
    margin-top: 15px;
  }

  #the_learning_experience {
    margin-top: 0;
  }

  .white_right_shadow {
    font-size: 95px;
  }

  .black_sub_text_r {
    font-size: 85px;
    margin-top: -64px;
  }


}



@media(max-width:1000px) {



  .mobile_section {
    display: block;
  }

  .web_section {
    display: none;
  }

  #header {
    background: transparent;
    box-shadow: none;
  }

  #header-left {
    display: none;
  }

  #header-center {
    display: none;
  }

  #header-right {
    left: 95%;
  }

  .setting_menu {
    padding-top: 25px;
  }

  .info_text_left {
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
    text-align: center;
    margin-top: 50px;
  }

  .info_text_left_white {
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
    margin-top: 65px;
    text-align: center;
  }

  .hr_cont {
    width: 0;
  }

  .hr_left {
    width: 60vw;
    margin-left: 20vw;
  }

  .hr_left_white {
    width: 60vw;
    margin-left: 20vw;
  }

  .info_text_right {
    margin-right: 80px;
    width: 80%;
    margin-left: 80px;
    text-align: center;
    width: calc(100% - 160px);
  }

  .info_text_right_white {
    margin-right: 80px;
    width: 80%;
    margin-left: 80px;
    text-align: center;
    width: calc(100% - 160px);
  }

  .hr_right {
    display: none;
  }

  .hr_right_white {
    display: none;
  }

  .black_sub_text_right {
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
  }

  .black_sub_text_r {
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
    margin-top: -64px;
    margin-bottom: 30px;
    font-size: 80px;
  }

  .info_text {
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
  }

  .white_sub_text {
    text-align: center;
    margin-left: 80px;
    margin-right: 80px;
    width: calc(100% - 160px);
  }

  .black_sub_text {
    text-align: center;
  }

  .black_text {
    text-align: center;
  }

  .white_shadow {
    text-align: center;
  }

  .bi-gear {
    fill: #b2b2b2;
  }

  .white_right_shadow {
    margin-right: 80px;
    margin-left: 80px;
    font-size: 90px;
    width: calc(100% - 160px);
  }

  .link_button_le {

    justify-self: center;
  }

}

@media(max-width:900px) {

  .black_text {
    margin-top: -50px;
    font-size: 58px;
  }

  .white_shadow {
    font-size: 68px;
  }

  .white_right_shadow {
    font-size: 75px;
    width: calc(100% - 160px);
    text-align-last: center;
  }

  .black_sub_text_r {
    font-size: 65px;
    text-align-last: center;
    width: calc(100% - 160px);
  }


}

@media(max-width:800px) {
  .white_shadow {
    font-size: 60px;
  }

  .black_text {
    font-size: 50px;
  }

  .white_right_shadow {
    font-size: 60px;
  }

  .black_sub_text_r {
    font-size: 50px;
    margin-top: -40px;
  }

  .link_button {
    font-size: 13px;
  }
}

@media(max-width:700px) {
  .white_shadow {
    font-size: 50px;
    margin-top: 25px;
  }

  .black_text {
    font-size: 45px;
    margin-top: -40px;
  }

  .white_right_shadow {
    font-size: 50px;
    margin-top: 0px;
  }

  .black_sub_text_r {
    font-size: 45px;
  }
}

@media(max-width:600px) {

  .coming_soon_text {
    font-size: 30px !important;
  }

  #header-left {
    height: 23px;
    top: 20px;
    left: 5px;
  }

  #header-center {
    height: 17px;
    top: 22px;
    left: calc(50% - 25px);
  }

  #header-right {
    left: 90%;
  }

  #navbar {
    left: 90%;
  }

  .black_text {
    font-size: 35px;
    margin-top: -30px;
  }

  .info_text_left {
    font-size: 16px;
    width: calc(100% - 160px);
  }

  .white_shadow {
    font-size: 40px;
  }

  .black_sub_text_right {
    font-size: 18px;
  }

  .info_text_right {
    font-size: 15px;
    width: calc(100% - 160px);
  }

  .white_sub_text {
    font-size: 30px;
  }

  .info_text_left_white {
    font-size: 15px;
    width: calc(100% - 160px);
  }

  .info_text_right_white {
    font-size: 15px;
    width: calc(100% - 160px);
  }

  .info_text {
    font-size: 15px;
    width: calc(100% - 160px);
  }

  .white_right_shadow {
    font-size: 40px;
    margin-top: 0px;
  }

  .black_sub_text_r {
    font-size: 35px;
    margin-top: -30px;
  }

  .link_button_le {
    width: 300px;
    font-size: 12px;
  }
}

@media(max-width:500px) {
  .white_shadow {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
  }

  .black_text {
    font-size: 25px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -20px;
    width: calc(100% - 60px);
  }

  .info_text_left {
    margin-top: 25px;
  }

  .info_text_left_white {
    margin-top: 25px;
  }

  .black_sub_text_r {
    margin-bottom: 20px;
  }

  .info_text_right {
    margin-top: 15px;
  }

  .white_right_shadow {
    font-size: 30px;
    margin-left: 30px;
    margin-right: 30px;
    width: calc(100% - 60px);
  }

  .black_sub_text_r {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: -20px;
    width: calc(100% - 60px);
    font-size: 25px;
  }
}

@media(max-width:400px) {

  .white_shadow {
    font-size: 25px;
  }

  .black_text {
    font-size: 20px;
  }

  .white_right_shadow {
    font-size: 25px;
  }

  .black_sub_text_r {
    font-size: 20px;
  }

  .white_sub_text {
    font-size: 22px;
  }

  .info_text_left_white {
    font-size: 13px;
  }
}